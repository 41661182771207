.ownCard:hover {
    background-color: #1565c0;
}

.ownCard {
    height: 100pt;
    background-color: rgb(51, 150, 255);
}

.sharedReadCard {
    height: 100pt;
    background-color: rgb(201, 235, 255);
}

.sharedReadCard:hover {
    background-color: #1565c0;
}

.sharedWriteCard {
    height: 100pt;
    background-color: rgb(151, 220, 255);
}

.sharedWriteCard:hover {
    background-color: #1565c0;
}

.sharedAdminCard {
    height: 100pt;
    background-color: rgb(101, 185, 255);
}

.sharedAdminCard:hover {
    background-color: #1565c0;
}
