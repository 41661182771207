body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: 100vh;
    margin: 0;
    overflow: hidden;
    width: 100vw;
}

:root {
    --cardHeaderHeight: 30px;
    --colorPrimary: #1976d2;
    --colorSecondary: rgb(51, 150, 255);
    --global-padding-5: 5px;
    --global-padding-10: 10px;
    --taskBarHeight: 25%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.white {
    color: white !important;
}

.background-primary {
    background-color: var(--colorPrimary) !important;
}

.background-secondary {
    background-color: var(--colorSecondary) !important;
}

.full-screen {
    width: 100vw;
    height: 100vh;
}

.mr-5 {
    margin-right: 5px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.p-5 {
    padding: var(--global-padding-5) !important;
}

.pt-5 {
    padding-top: var(--global-padding-5) !important;
}

.pr-5 {
    padding-right: var(--global-padding-5) !important;
}

.pb-5 {
    padding-bottom: var(--global-padding-5) !important;
}

.pt-10 {
    padding-top: var(--global-padding-10) !important;
}

.no-padding {
    padding: 0 !important;
}

.pressable {
    cursor: pointer;
}

.flex-center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fade-in {
    -webkit-animation: fadein 1.2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1.2s; /* Firefox < 16 */
    -ms-animation: fadein 1.2s; /* Internet Explorer */
    -o-animation: fadein 1.2s; /* Opera < 12.1 */
    animation: fadein 1.2s;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.round-button {
    width: 35%;
}
.round-button-circle {
    width: 300%;
    padding-bottom: 0;
    border-radius: 50%;
    border: 5px solid #cfdcec;
    overflow: hidden;

    background: #4679bd;
    box-shadow: 0 0 1px gray;
}
.round-button-circle:hover {
    background: #30588e;
}
.round-button a {
    display: block;
    float: left;
    width: 100%;
    padding-top: 50%;
    padding-bottom: 50%;
    line-height: 1em;
    margin-top: -0.5em;
    margin-bottom: -0.5em;

    text-align: center;
    color: #e2eaf3;
    font-family: Verdana;
    font-weight: bold;
    text-decoration: none;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
