.cardHeaderHeight {
    height: 30px;
}

.cardHeight {
    height: 100%;
}

.cardTextContainer {
    overflow-y: auto;
    max-height: calc(25vh - 60px);
}

.slide {
    top: 0;
    left: 0;
    height: calc(25vh - 10px);
    width: 100%;
    background-color: white;
    z-index: 99;
    position: absolute;
}
